/*!
 * Copyright 2022
 * Mystory Norge As
 * https://mystory-norge.no
 */
@import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');

.img-head img {
  max-width: 100%;
}
